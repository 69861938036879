/* Poppins fonts */

/*Light*/
@font-face {
  font-family: "Poppins";
  src: url('../webfonts/Poppins/Poppins-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}

/*Regular*/
@font-face {
  font-family: "Poppins";
  src: url('../webfonts/Poppins/Poppins-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

/*Medium*/
@font-face {
  font-family: "Poppins";
  src: url('../webfonts/Poppins/Poppins-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

/*Semibold*/
@font-face {
  font-family: "Poppins";
  src: url('../webfonts/Poppins/Poppins-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

/* Bold */
@font-face {
  font-family: "Poppins";
  src: url('../webfonts/Poppins/Poppins-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}